/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSporforyaContext } from '../context';

export const getQueryParams = (params?: Record<string, string | number>) => {
  if (!params) return '';
  return Object.entries(params).reduce((all, [key, value]) => {
    return all ? `${all}&${key}=${value}` : `?${key}=${value}`;
  }, '');
};

const useApi = <TRes,>(
  path: string,
  config: RequestInit = {},
  autolaunch: boolean = false,
) => {
  const [response, setResponse] = useState<TRes | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState<number>();
  const { baseUrl, token } = useSporforyaContext();

  const launchCall = useCallback(
    async (
      launchConfig?: RequestInit,
      queryParams?: Record<string, string | number>,
      newPath?: string,
    ) => {
      const updatedConfig = launchConfig ?? config;
      const url = `${baseUrl}${newPath ?? path}${getQueryParams(queryParams)}`;
      setIsLoading(true);
      setError(false);
      try {
        const data = await fetch(url, {
          ...updatedConfig,
          headers: {
            ...updatedConfig.headers,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: token ? `Bearer ${token}` : '',
          },
        })
          .then(response => {
            setStatus(response.status);
            if (response.status !== 200 && response.status !== 201) {
              throw Error;
            }
            return response?.json();
          })
          .then(data => data as TRes);
        setResponse(data);
      } catch (error) {
        setError(true);
      }
      setIsLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (autolaunch) {
      setIsLoading(true);
      launchCall();
    }
  }, []);

  return { response, error, status, isLoading, launchCall };
};

export default useApi;
