import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Table, { TableColumn } from '../../components/ui/Table';
import { AdminDownloded, AdminTable } from '../../models/admin';
import { AdminDownloadedData, AdminToAdminTable } from '../../utils/admin';
import useAdmins from './useAdmins';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import Alert from '../../components/Alert';
import { useSporforyaContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/ui/Input';

type Props = {
  userRole?: string;
};

const AdminTypes = [
  {
    id: 'all',
    value: 'All',
  },
  {
    id: 'super-admin',
    value: 'Super Admin',
  },
  {
    id: 'admin',
    value: 'Admin',
  },
];

const Admins = ({ userRole = 'super-admin' }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useSporforyaContext();
  const [adminsData, setAdminsData] = useState<{
    headers: TableColumn<AdminTable>[];
    data: AdminTable[];
  }>({ headers: [], data: [] });

  const [adminsDownloadedData, setAdminsDownloadedData] = useState<{
    data: AdminDownloded[];
  }>({ data: [] });
  const {
    admins,
    resetPassword,
    deleteEmail,
    changeStatus,
    applyFilters,
    isLoading,
    error,
  } = useAdmins();

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [totalPages, setTotalPages] = useState<number | undefined>();

  let currPage = 1;
  let contentPerPage = 20;

  const resetPageSize = () => {
    if (admins) {
      setCurrentPage(1);
      setTotalPages(Math.ceil(admins.length / contentPerPage));
    }
  };

  const findSlicingIndex = () => {
    const startIndex = currPage * contentPerPage - contentPerPage;
    const endIndex = startIndex + contentPerPage;
    return { startIndex, endIndex };
  };

  useEffect(() => {
    if (admins) {
      resetPageSize();
      getPaginatedData();
      setAdminsDownloadedData(AdminDownloadedData(admins));
    }
  }, [admins]);

  const [filters, setSelectedFilters] = useState<{
    AdminTypes?: DropdownOption;
    searchString?: string;
  }>();

  const handleFilterByType = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, AdminTypes: value }));
    currPage = 1;
    if (admins) {
      let newAdmins = admins?.filter((item: any) => item?.role === value?.id);
      setCurrentPage(currPage);
      setTotalPages(
        Math.ceil(
          value?.id === 'all'
            ? admins.length / contentPerPage
            : newAdmins.length / contentPerPage,
        ),
      );
      let { startIndex, endIndex } = findSlicingIndex();
      setAdminsData(
        AdminToAdminTable(
          value?.id === 'all'
            ? admins.slice(startIndex, endIndex)
            : newAdmins.slice(startIndex, endIndex),
        ),
      );
      setAdminsDownloadedData(
        AdminDownloadedData(value?.id === 'all' ? admins : newAdmins),
      );
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    applyFilters({ sortBy: 'desc' }, true);
  };

  /* Local Pagination feature */

  const getPaginatedData = () => {
    let filter = filters?.AdminTypes?.id;
    let { startIndex, endIndex } = findSlicingIndex();
    if (admins && filter !== 'provider' && filter !== 'user') {
      const paginatedData = admins.slice(startIndex, endIndex);
      setAdminsData(AdminToAdminTable(paginatedData));
    }
    if (admins && (filter === 'provider' || filter === 'user')) {
      let newAdmins = admins?.filter((item: any) => item?.type === filter);
      const paginatedData = newAdmins?.slice(startIndex, endIndex);
      setAdminsData(AdminToAdminTable(paginatedData ?? []));
    }
  };

  const nextPage = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      currPage = currentPage + 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const prevPage = () => {
    if (currentPage && totalPages && currentPage > 1) {
      currPage = currentPage - 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const currentAction = useRef<string>();
  const activeAdmin = useRef<AdminTable>();
  const [alertDescription, setAlertDescription] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayStatusAlert, setDisplayStatusAlert] = useState(false);

  const onCloseAlert = () => {
    setDisplayAlert(false);
    setDisplayStatusAlert(false);
    activeAdmin.current = undefined;
    currentAction.current = undefined;
  };

  const handleResetPasswordAdmin = () => {
    const currentAdmin = activeAdmin.current;
    if (!currentAdmin) {
      return;
    }
    resetPassword(currentAdmin?.email);
    setDisplayAlert(false);
  };

  const handleDeleteEmail = () => {
    const currentAdmin = activeAdmin.current;
    if (!currentAdmin) {
      return;
    }
    deleteEmail(currentAdmin?.id);
    const data = adminsData.data.reduce((all, admin) => {
      if (admin?.adminId !== currentAdmin.adminId) {
        all.push(admin);
      }
      return all;
    }, [] as AdminTable[]);

    setAdminsData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleChangeStatus = () => {
    const currentAdmin = activeAdmin.current;
    if (!currentAdmin) {
      return;
    }
    changeStatus(currentAdmin?.userId);
    setDisplayStatusAlert(false);
  };

  const handleSearchChange = (value: string) => {
    setSelectedFilters(prev => ({ ...prev, search: value }));
    applyFilters({ searchString: value });
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'resetPassword':
        handleResetPasswordAdmin();
        return;
      case 'deleteEmail':
        handleDeleteEmail();
        return;
      case 'changeStatus':
        handleChangeStatus();
        return;
      default:
        return;
    }
  };

  const handleResetPasswordAlertAdmin = (row: AdminTable) => {
    setAlertDescription(
      `Are you sure you want to reset password for ${userRole}?`,
    );
    setDisplayAlert(true);
    activeAdmin.current = row;
    currentAction.current = 'resetPassword';
  };

  const handleDeleteAlertUser = (row: AdminTable) => {
    setAlertDescription(`Are you sure you want to delete this email?`);
    setDisplayAlert(true);
    activeAdmin.current = row;
    currentAction.current = 'deleteEmail';
  };

  const handleChangeStatusAlertAdmin = (row: AdminTable) => {
    setAlertDescription(`Are you sure you want to change status`);
    setDisplayStatusAlert(true);
    activeAdmin.current = row;
    currentAction.current = 'changeStatus';
  };

  const renderActions = (row: AdminTable) => (
    <div className="flex flex-wrap gap-2">
      <button
        className="bg-primary-main text-white text-[12px] font-semiBold px-2 py-1 rounded-sm"
        onClick={() => handleResetPasswordAlertAdmin(row)}>
        Reset Password
      </button>
      {userInfo?.role === 'super-admin' && (
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            checked={row?.status === 'Active' ? true : false}
            onChange={() => handleChangeStatusAlertAdmin(row)}
          />
          <div className="w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-primary-main dark:peer-focus:ring-primary-main rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-primary-main"></div>
          <span className="ml-1 text-xs font-medium text-gray-900 dark:text-gray-500">
            {row?.status === 'Active' ? 'De-activate' : 'Activate'}
          </span>
        </label>
      )}
    </div>
  );

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}
      {displayStatusAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide mb-4">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {userRole}
        </h1>
        <div className="w-full flex flex-wrap justify-around xl:justify-between items-center py-2 space-y-4">
          <div className="flex flex-wrap gap-4 items-center">
            <Input
              className="w-full min-[480px]:w-[200px]"
              placeholder="Search name or email"
              value={filters?.searchString}
              onInput={handleSearchChange}
            />
            <Dropdown
              defaultValue={filters?.AdminTypes}
              placeholder="Type"
              className="w-full min-[480px]:w-[200px]"
              options={AdminTypes}
              onSelectOption={handleFilterByType}
            />
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
          </div>
          <div className="flex gap-4 flex-wrap items-center">
            <CSVLink
              className="flex md:justify-end w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
              data={adminsDownloadedData.data}
              filename="Sporti_Users">
              Download CSV
            </CSVLink>
            {userInfo?.role === 'superadmin' && (
              <Button
                className="w-full min-[480px]:w-[200px]"
                text="Add new admin"
                onClick={() => navigate('/new-admin')}
              />
            )}
          </div>
        </div>
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              getActions={renderActions}
              data={adminsData?.data}
              columns={adminsData?.headers}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Admins;
