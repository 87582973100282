/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import { useSporforyaContext } from '../../context';
import useCache from '../../hooks/useCache';
import { AUTHENTICATION_TOKEN_KEY } from '../../utils/auth';
import useLogin from './useLogin';
const logoBlue = require('../../assets/athlos-logo-blue.png');

const LoginPage = () => {
  const { token } = useSporforyaContext();
  const navigate = useNavigate();
  const emailRef = useRef('');
  const passwordRef = useRef('');

  const {
    login,
    response,
    isLoading,
    error,
    status,
    rememberMe,
    setRememberMe,
  } = useLogin();
  const { removeCacheData } = useCache();

  useEffect(() => {
    if (response) {
      navigate('/');
    }
  }, [response]);

  useEffect(() => {
    if (token) {
      navigate('/');
    } else {
      removeCacheData(AUTHENTICATION_TOKEN_KEY);
    }
  }, []);

  const onSubmitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    login(emailRef.current, passwordRef.current);
  };

  const onEmailChange = (value: string) => {
    emailRef.current = value;
  };
  const onPasswordChange = (value: string) => {
    passwordRef.current = value;
  };

  const handleChecked = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="flex w-full h-full relative ">
      <div className="overlay"></div>
      <div className="w-full h-[100vh] bg-[url('./assets/sfy-login-bg.jpeg')] bg-cover bg-center " />
      <div className="absolute">
        <img src={logoBlue} alt="sporforya-logo" />
      </div>

      <div className="absolute bg-white border rounded-md md:right-20 top-20 p-4">
        <form
          className="w-[360px] md:w-[300px] h-auto flex flex-col justify-between space-y-4"
          onSubmit={onSubmitHandle}>
          <div className="space-y-2 mb-4">
            <img src={logoBlue} alt="sporforya-logo" />
            <p>Admin Dashboard</p>
          </div>
          <h1 className="text-xl md:text-3xl  text-black">Sign in</h1>
          <Input
            required
            className="max-[920px]:border-transparent"
            type="email"
            placeholder="Email Address"
            onInput={onEmailChange}
          />
          <Input
            required
            className="max-[920px]:border-transparent"
            type="password"
            placeholder="Password"
            onInput={onPasswordChange}
          />
          <div className="flex justify-between">
            <div className="flex items-center gap-2 text-sm">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                className="text-primary-main focus:outline-primary-main"
                onChange={handleChecked}
              />
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <a
              href="/forgot-password"
              className="underline hover:text-primary-main cursor-pointer text-sm">
              Forgot password
            </a>
          </div>
          <Button type="submit" text="Sign in" showLoader={isLoading} />
          <span
            className={`text-xs text-center text-red-500 font-bold ${
              !error && 'opacity-0'
            }`}>
            {status === 401
              ? 'Admin deactivated. Please contact owner.'
              : 'Your username or password is wrong, please try again'}
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
