import { ReactElement, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { LogOut } from 'lucide-react';

const logo = require('../../assets/athlos-logo-blue.png');

export type SideNavLink = {
  path: string;
  id: string;
  label: string;
  icon?: ReactElement;
  external?: boolean;
  heading?: string;
};

type Props = {
  links: SideNavLink[];
  open: boolean;
  setOpen: (value: boolean) => void;
  width: number;
};

const Sidenav = ({ open, setOpen, width, links }: Props) => {
  const toggleSidenav = () => {
    setOpen(!open);
  };

  const fnLogout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      {open && (
        <div className="h-full w-[260px] flex flex-col pt-6 px-4 bg-secondary-main z-40 fixed right-0 md:left-0 overflow-auto">
          <button
            className="flex items-center text-white justify-between pb-4"
            onClick={toggleSidenav}>
            <div className='flex gap-2 items-center'>
              <img src={logo} alt="sporforya-logo" className="w-10" />
              <p className='text-white text-2xl font-bold uppercase'>Athlos</p>
            </div>
            <div>
              <CloseIcon className=" fill-white" />
            </div>
          </button>
          <div className="pt-6 flex flex-col space-y-8">
            {links?.map(({ id, label, icon, path, heading, external }) => {
              return (
                <div key={id} className="space-y-4">
                  {heading && (
                    <span className="text-white text-xl mt-4">{heading}</span>
                  )}
                  {!external ? (
                    <NavLink
                      className="w-fit text-xl text-white flex items-center gap-2"
                      to={path}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? '700' : '500',
                      })}>
                      {icon}
                      {label}
                    </NavLink>
                  ) : (
                    <a
                      rel="noreferrer"
                      target={'_blank'}
                      href={path}
                      className="w-fit text-xl text-white font-medium">
                      {label}
                    </a>
                  )}
                </div>
              );
            })}
          </div>

          <button
            onClick={fnLogout}
            className="w-fit text-xl text-white py-4 font-medium flex items-center gap-2 mt-4">
            <LogOut />
            Log out
          </button>
        </div>
      )}
      {!open && (
        <div className="h-full w-[70px] flex flex-col items-center pt-6 px-2 bg-secondary-main z-40 fixed overflow-auto">
          <button
            className="absolute top-6 rounded-full bg-secondary-main z-50 pb-4"
            onClick={toggleSidenav}>
            <MenuIcon className="fill-white" />
          </button>
          <div className="pt-16 flex flex-col items-center space-y-8">
            {links.map(({ id, label, path, icon, external }) =>
              !external ? (
                <NavLink
                  className="w-fit text-xl text-white"
                  to={path}
                  key={id}
                  style={({ isActive }) => ({
                    fontWeight: isActive ? '700' : '500',
                  })}>
                  {icon}
                </NavLink>
              ) : (
                <a
                  rel="noreferrer"
                  key={id}
                  target={'_blank'}
                  href={path}
                  className="w-fit text-xl text-white font-medium">
                  {icon}
                </a>
              ),
            )}
            <button
              onClick={fnLogout}
              className="w-fit text-xl text-white py-4 font-medium mt-4">
              <LogOut />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidenav;
