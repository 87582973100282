import { Navigate } from 'react-router-dom';
import { useSporforyaContext } from '../../context';

type Props = {
  children: JSX.Element;
};

const OwnerProtected = ({ children }: Props) => {
  const { userInfo } = useSporforyaContext();
  if (userInfo?.role !== 'super-admin') {
    return <Navigate to="/admins" replace />;
  }
  return children;
};
export default OwnerProtected;
