import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Bookings } from '../../models/bookings';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';

import { ReactComponent as StarIcon } from '../../assets/star.svg';
import { Provider } from '../../models/provider';

const BookingDetailsPage = () => {
  const { state } = useLocation();

  const bookingData: Bookings = useMemo(() => {
    return state;
  }, [state]);

  const getParsedEventSchedule = useCallback(
    ({ start, end }: { start: string; end: string }) => {
      const startDateTime = new Date(start);
      const endDateTime = new Date(end);

      // Parse Date Range
      const startDate = startDateTime.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
      });
      const endDate = endDateTime.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
      const dateRange = `${startDate} - ${endDate}`;

      // Parse Time
      const startTime = startDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const endTime = endDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const timeRange = `${startTime} - ${endTime}`;

      return [dateRange, timeRange];
    },
    [],
  );

  if (!bookingData) {
    return <Navigate to={'/bookings'} />;
  }

  return (
    <div className="bg-[#f7faff] min-h-full py-6 px-4 mx-auto">
      <div className="max-w-full w-full pt-6">
        <div className="flex items-center border-b pb-4 mb-4">
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold leading-tight mb-1">
                <strong>Listing Title:</strong> {bookingData.listing.title}
              </h2>
              <div className="flex items-center text-xs  leading-tight capitalize">
                <div className="w-2 h-2 rounded-full bg-green-400 mr-2"></div>
                {bookingData.status}
              </div>
            </div>
            <div className="text-sm  leading-tight font-semibold mb-2">
              <div className="flex items-center justify-between ">
                {bookingData.totalPrice && (
                  <p>
                    <strong>Price:</strong> ${bookingData.totalPrice}
                  </p>
                )}
                {bookingData.transferId && (
                  <p>
                    <strong>Transfer ID:</strong> {bookingData.transferId}
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between ">
                {bookingData.userId && (
                  <p>
                    <strong>User ID:</strong> {bookingData.userId}
                  </p>
                )}
                {bookingData.providerId && (
                  <p>
                    <strong>Provider ID:</strong> {bookingData.providerId}
                  </p>
                )}
              </div>
            </div>

            <hr className="w-3/4 my-3" />
            <div className="grid gap-2 grid-cols-2">
              {bookingData.provider ? (
                <DetailsView
                  title="Provider Details"
                  person={bookingData.provider}
                />
              ) : null}
              {bookingData.user ? (
                <DetailsView title="User Details" person={bookingData.provider} />
              ) : null}
            </div>

            {bookingData.pointsAwarded && (
              <span className="text-xs leding-tight">
                Points: {bookingData.pointsAwarded}
              </span>
            )}

            {bookingData.selectableDateTime && (
              <div className="flex items-center">
                <CalendarIcon className="w-4 h-4 mr-2" />
                <div className="pl-3">
                  <p className="text-sm leading-tight">
                    {getParsedEventSchedule(bookingData.selectableDateTime)[0]}
                  </p>
                  <p className="text-xs text-[#909193] leading-tight">
                    {getParsedEventSchedule(bookingData.selectableDateTime)[1]}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type DetailsViewProps = {
  title: string;
  person: Provider;
};

const DetailsView = ({ title, person }: DetailsViewProps) => {
  return (
    <div className="bg-white p-2 rounded-lg border border-gray-300">
      <h4 className="text-2xl font-bold leading-tight mb-1">{title}</h4>
      {person?.Profile.avatar ? (
        <div className="w-10 h-10 pr-4">
          <ImageRenderer
            url={person.Profile.avatar}
            name={ "Static"}
          />
        </div>
      ) : null}
      <p className="text-sm  leading-tight capitalize mb-2">
        <strong>Name: </strong>
        {"Static"}
      </p>
      {}
      <p className=" flex items-center gap-2 text-sm  leading-tight capitalize">
        <StarIcon className="w-4 h-4" />
        {0}
      </p>
    </div>
  );
};

export default BookingDetailsPage;

function ImageRenderer({ url, name }: { url: string; name: string }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = () => {
      setImageLoaded(false);
    };
  }, [url]);
  return (
    <>
      {imageLoaded ? (
        <img className="w-full h-full rounded-full" src={url} alt={name} />
      ) : (
        <p className="w-10 h-10 flex justify-center items-center font-bold text-white text-4xl uppercase rounded-full bg-secondary-main">
          {name[0]}
        </p>
      )}
    </>
  );
}
